<template>
  <b-card>
    <validation-observer #default="{handleSubmit}">
      <b-form @submit.prevent>
        <text-area-input
          id="notes"
          name="Notes"
          :text.sync="notes"
        />
        <submit-button v-bind="{submit:updateNotes,handleSubmit}" />
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import TextAreaInput from '@/common/components/common/FormInputs/TextAreaInput.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'SuggestionNotes',
  components: { SubmitButton, TextAreaInput },
  data() {
    return {
      notes: '',
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  created() {
    this.getNotes()
  },
  methods: {
    getNotes() {
      this.$activities.get(`/internalops/scrapped/event/${this.$route.params.id}/note/`).then(res => {
        this.notes = res.data.data.notes
      })
    },
    updateNotes() {
      return this.$activities.post(`/internalops/scrapped/event/${this.$route.params.id}/note/`, { notes: this.notes }).then(() => {
        this.successfulOperationAlert('Notes updated successfully')
        this.$router.back()
      })
    },
  },
}
</script>

<style>

</style>
